import 'chosen-js';
initChosen();

$(document).ready(function() {
    initChosen();

    // Reinitialize chosen dropdowns on nested fields
    $('body').on('click', '.add_fields', function() {
      initChosen();
    })

    // Reinitialize chosen dropdowns on nested fields
    $('body').on('click', '.orders input[type="submit"]', function() {
      setTimeout(function() {
        initChosen();
      }, 200);
      
    })

    $('body').on('change', '.chosen-select-order', function(e) {
      e.preventDefault();
      var field = $(this).data('field')
      setTimeout(function() {
        $(`.line-items-count[data-field="${field}"]`).trigger("focus");
      }, 150)
      
    })

    waitForElm('.slide-out-container .chosen-select').then(function (elm) {
      initChosen();
      $('.chosen-select.toggle-open').trigger('chosen:open');
    });

})

function initChosen() {
  $(".chosen-select").chosen({
    width: "100%",
    no_results_text: "Nothing found.",
    allow_single_deselect: true,
    max_shown_results: 20,
  })
}


function waitForElm(selector) {
  return new Promise(function(resolve) {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(function(mutations) {
          if (document.querySelector(selector)) {
              resolve(document.querySelector(selector));
              observer.disconnect();
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}
  

