
$(document).ready(function() {

    if ($('.alert.with-progress').length) {
        $('.alert.with-progress').addClass('bar-animate');

        $('body').on("animationend", ".bar", function() {
            if ($('.bar').width() == $('.bar').parent().width()) {
                $('.alert').animate({"right": "-500px"});
             }
        })
    }

})
