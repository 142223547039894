$(document).ready(function() { 

    var cookies = Cookies.get();

    if (cookies.sidebarToggled == 'true') {
        $('#sidebar').addClass('sidebar-toggled');
        $('#sidebar-action').css('display', 'block');
        Cookies.set('sidebarToggled', 'false');
    }


    $(document).on('mouseover', '#sidebar', function(e) {
        
        $('#sidebar').addClass('sidebar-toggled');    
                
        if ($('.submenu').css("display") == "none") {
            $('.submenu').collapse('show');
        }

        $('#sidebar-action').fadeIn('fast');
    
    })

    $(document).on('mouseleave', '#sidebar', function(e) {
        
        if (!$('#sidebar').hasClass('keep-toggled')) {
            $('#sidebar').removeClass('sidebar-toggled');
            $('.submenu').collapse('hide');
            $('#userSubmenu').collapse('hide');
        } 
        
        $('#sidebar-action').fadeOut('fast');
        
    });

    $(document).on('click', '#sidebar a', function() {
        Cookies.set('sidebarToggled', 'true'); 
    });

    $('#toggle-sidebar-mobile').on('click', function() {
        $('#sidebar').addClass('keep-toggled');
        $('#sidebar').addClass('sidebar-toggled');
        $('.wrapper-main-content').addClass('with-toggled-nav');
        $('#sidebar').addClass('mobile-toggled')
        $('#sidebar-action').css('display', 'block');
    })

});

$(document).on('turbolinks:before-cache', function() {
    Turbolinks.clearCache()
});
