$(document).ready(function() {
	var keybuffer = [];
  var reading = false;

  function displayFlash(message, type) {
    $('.alert-success').removeClass('alert');
    $('.alert-success').removeClass('alert-success');
    $('.alert-danger').removeClass('alert');
    $('.alert-danger').removeClass('alert-danger');
    $('.flash-msg').html('<div class="alert alert-' + type +'">' + message + '</div>');
    $("html, body").animate({ scrollTop: 0 }, "fast");
  }

  function handleResponse(response) {
    if (response.error && response.error.length > 0) {
      displayFlash(response.error, "danger");
    } else if (response.message && response.message.length > 0){
      displayFlash(response.message, "success");
    } else {
      $('.flash-msg').html('');
    }
    
    if (response.url && response.url.length > 0) {
      window.location = response.url;
    }
  }

	function scanBarcode(scanned_barcode){
		scanned_barcode = scanned_barcode.replace('\r', '');
		if ($('body.checkouts').length) {
			Rails.ajax({
				type: "POST", 
				url: "/checkouts/scan",
				data: 'barcode=' + scanned_barcode,
				contentType: 'json',
				error: function(data) {
          displayFlash("Unable to create checkout", "danger");
				}
			})
		} else {
      Rails.ajax({
        type: "POST",
        url: "/inventory_assets/scan",
        data: 'barcode=' + scanned_barcode,
        contentType: 'json',
        success: function(data) {
          if(data != null && data.id != null){
              window.location = '/inventory_assets/' + data.id;
          }
        },
        error: function(data) {
          displayFlash("An error occurred.", "danger");
        }
      })
    } 
	};

	function press(event) {
    // don't do anything if the slide out form is open, allow the scan to function like normal
    if ($('div.slide-out-container').length === 0) {

      // ignore default tab behavior and submit the barcode via ajax if we've scanned more than 5 numbers
      if (keybuffer.length > 5 && (event.which === 13 || event.which === 9)) {
        event.preventDefault();
        return send();
      }

      var number = event.which - 48;
      if (number < 0 || number > 9) {
        return;
      }
      keybuffer.push(number);

      if(!reading){
        reading=true;
        setTimeout(()=>{
          keybuffer=[];
          reading=false;
        }, 200);
      }
    }
	}

  // don't check for scans on the login page
  if ($('body.sessions').length === 0) {
    $(document).on("keydown", press);
  }

	function send() {
    var barcode 
		scanBarcode(keybuffer.join(""));
		keybuffer.length = 0;
	}

});
