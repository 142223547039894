// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery")
require("jquery-ui")
require("chosen-js")
require("flatpickr")
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import Cookies from 'js-cookie'
window.Cookies = Cookies

import chosen from 'chosen-js'
window.chosen = chosen

import 'chosen-js/chosen.css'
import "@fortawesome/fontawesome-pro/css/all";
import '../stylesheets/application.scss';

require("flatpickr/dist/flatpickr.css");
require("packs/nested-forms/addFields")
require("packs/nested-forms/removeFields")
require("packs/scan.js");

require("@popperjs/core");
require("bootstrap/dist/js/bootstrap.min.js");
require("./slide_out_form.js");
require("./navigation.js");
require("./chosen.js")
require("./datepicker.js")
require('./filter_form.js');
require("./messages.js");

import setupTransitions from './animate.js';
setupTransitions();


Rails.start()
global.Rails = Rails;
ActiveStorage.start()

const images = require.context('../images', true, /\.(gif|jpg|png|svg)$/i)
const imagePath = (name) => images(name, true)
